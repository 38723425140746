import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Button from '../components/Button'
import BlockRSO from '../components/BlockRSO'
import styled from 'styled-components'
import COLORS from '../utils/colors'
import '../styles/contact.scss'

const ContactSection = styled.div`

 margin-top: 72px;

h2{
    margin: 24px 0;
    font-size: 1.8rem;
    text-transform: none;
    font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    color:${COLORS.secundary};
    
}

p, ol>li{
    
    text-transform: none;
    font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    color:${COLORS.secundary};
    
}

ol{
    margin: 24px 0 0 48px;

    li{
        margin-bottom:8px;
    }
}

   

    .contact-img{
        max-width:466px;
        margin:auto;
    }

    .contact-display{
      
        margin: -72px auto 0;
     
    }

    .mail, .para-rso{
        text-align: center;
        
    }

    .mail{

        position: relative;
        background-color: ${COLORS.primary};
        padding: 48px 0;
        clip-path: polygon(0 15%, 100% 0%, 100% 85%, 0% 100%);
        margin-top: 48px;

        p,span,i{
            color: ${COLORS.secundary};
        }
    }

    i{
        display: block;
        margin-bottom: 24px;
    }

    span.mini{
        display: block;
        font-size: 0.8rem;
        text-align: center;
    }

    .para-rso{
        margin-top: 48px;
    }

    .para-mail{
        font-size:2rem;
    }
`

const contactImage = graphql`{
    contact:file(relativePath:{eq:"contact.png"}){
      childImageSharp{
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `

const ContactPage = () => {
    const data = useStaticQuery(contactImage)
    return (
        <Layout theme="contact" page ="contact">
            <SEO title="Un p'tit mail et c'est parti !"/>
            <h1>Contact</h1>
            
            <ContactSection>
            <h2>Prêt·e à vous lancer ?</h2>
            <p>Voici les premiers pas pour commencer à travailler ensemble :</p>
            <ol>
                <li>Vous envoyez un e-mail à l'adresse : <a href="mailto:marylise.barthelemy@gmail.com?subject=Description de ma demande.">marylise.barthelemy[@]gmail.com</a></li>
                <li>Je vous réponds sous 48h pour fixer un RDV téléphonique de 30 minutes</li>
                <li>Nous entrons dans les détails de votre demande durant cet échange (gratuit)</li>
                <li>Je vous transmets une proposition commerciale adaptée à votre projet</li>
                <li>Une fois validée, on lance le top départ !</li>
            </ol>
            <Button ajoutClasse="contact-me reverse">J'envoie un e-mail</Button>
            <h2>Rejoignez ma communauté !</h2>
            <p>Je partage des astuces et conseils pour optimiser votre site web et votre communication visuelle sur Facebook et LinkedIn.</p>

            </ContactSection>
           
        </Layout>
    );
};

export default ContactPage;